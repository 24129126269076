@font-face {
  font-family: Brown;
  font-display: swap;
  src: url("brown-regular-webfont.d2138f6b.woff2") format("woff2"), url("brown-regular-webfont.07ce4322.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Brown;
  font-display: swap;
  src: url("brown-light-webfont.e328a78e.woff2") format("woff2"), url("brown-light-webfont.899bdd3c.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Brown;
  font-display: swap;
  src: url("brown-bold-webfont.d04cc1be.woff2") format("woff2"), url("brown-bold-webfont.dc5cc8ba.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
}

:focus, :active {
  outline: 0;
}

p:empty {
  display: none;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

html {
  color: #222;
}

::selection {
  text-shadow: none;
  background: #b3d4fc;
}

hr {
  border: 0;
  border-top: 1px solid #ccc;
  height: 1px;
  margin: 1em 0;
  padding: 0;
  display: block;
}

audio, canvas, iframe, img, svg, video {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.hidden, [hidden] {
  display: none !important;
}

.visually-hidden {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.visually-hidden.focusable:active, .visually-hidden.focusable:focus {
  clip: auto;
  white-space: inherit;
  width: auto;
  height: auto;
  margin: 0;
  position: static;
  overflow: visible;
}

.invisible {
  visibility: hidden;
}

.clearfix:before, .clearfix:after {
  content: "";
  display: table;
}

.clearfix:after {
  clear: both;
}

@media print {
  *, :before, :after {
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
    background: #fff !important;
  }

  a, a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  a[href^="#"]:after, a[href^="javascript:"]:after {
    content: "";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre, blockquote {
    page-break-inside: avoid;
    border: 1px solid #999;
  }

  tr, img {
    page-break-inside: avoid;
  }

  p, h2, h3 {
    orphans: 3;
    widows: 3;
  }

  h2, h3 {
    page-break-after: avoid;
  }
}

:root {
  --scrollbar-width: 0;
}

html {
  scroll-behavior: smooth;
  font-size: 10px;
  overflow-x: hidden;
}

@media (width <= 800px) {
  html {
    font-size: calc((100vw - var(--scrollbar-width)) / 100);
  }
}

@media (width >= 800px) {
  html {
    font-size: calc((100vw - var(--scrollbar-width)) / 198);
  }
}

@media (width >= 1980px) {
  html {
    font-size: 10px;
  }
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #215bdf;
  min-height: 100vh;
  font-family: Brown, sans-serif;
  overflow-x: hidden;
}

.header {
  background-color: #215bdf;
  position: relative;
}

.header__title {
  z-index: 10;
  width: 66.3rem;
  margin: 0 auto;
  padding: 15rem 0 0;
  position: relative;
}

.header__title img {
  width: 66.3rem;
  height: auto;
}

@media (width <= 800px) {
  .header__title {
    z-index: 12;
    padding: 20rem 0 0;
  }
}

.header__baseline {
  z-index: 12;
  text-align: right;
  padding: 5rem 20rem 10rem 0;
  position: relative;
}

.header__baseline img {
  width: 83.1rem;
  height: auto;
}

@media (width <= 800px) {
  .header__baseline {
    padding: 10rem 2.5rem 10rem 0;
  }
}

.header__cat {
  z-index: 15;
  width: 100%;
  position: absolute;
}

.header__cat--left {
  position: absolute;
  top: -20rem;
  left: 25%;
}

.header__cat--left img {
  width: 20.8rem;
  height: auto;
}

@media (width <= 800px) {
  .header__cat--left {
    left: 15%;
  }
}

.header__cat--right {
  position: absolute;
  top: -12rem;
  right: 25%;
}

.header__cat--right img {
  width: 25.5rem;
  height: auto;
}

@media (width <= 800px) {
  .header__cat--right {
    right: 15%;
  }
}

.header__wool {
  z-index: 11;
  position: absolute;
  top: 37rem;
  left: 3.5%;
}

.header__wool img {
  width: 130rem;
  height: auto;
}

@media (width <= 800px) {
  .header__wool {
    left: -30rem;
  }
}

.footer {
  background-color: #215bdf;
  justify-content: center;
  align-items: center;
  padding: 10rem 5rem;
  display: flex;
  position: relative;
}

@media (width <= 800px) {
  .footer {
    padding: 15rem 5rem 10rem;
    display: block;
  }
}

.footer a {
  color: #fff;
  font-size: 4.1rem;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

.footer__top {
  text-align: left;
  text-indent: 10rem;
  width: 50%;
}

.footer__top:after {
  content: "";
  background: url("lien-top.0b3473b3.svg") 0 0 / cover no-repeat;
  width: 9.8rem;
  height: 12.2rem;
  display: inline-block;
  position: absolute;
  top: 3rem;
  left: 3rem;
}

@media (width <= 800px) {
  .footer__top:after {
    top: 6rem;
    left: 20%;
  }

  .footer__top {
    text-indent: 0;
    text-align: center;
    width: 100%;
    margin: 0 0 5rem;
    display: block;
  }
}

.footer__mailto {
  text-align: right;
  width: 50%;
  padding-right: 12rem;
  line-height: 8rem;
}

.footer__mailto:after {
  content: "";
  background: url("lien-mailto.e44038e5.svg") 0 0 / cover no-repeat;
  width: 12rem;
  height: 8.8rem;
  display: inline-block;
  position: absolute;
  right: 3rem;
}

@media (width <= 800px) {
  .footer__mailto:after {
    right: 15%;
  }

  .footer__mailto {
    text-align: center;
    width: 100%;
    padding: 0;
    display: block;
  }
}

.form {
  padding: 20rem 0;
}

@media (width <= 800px) {
  .form {
    padding: 10rem 0;
  }
}

.form__field {
  text-align: center;
  width: 95rem;
  margin: 0 auto;
}

.form__label {
  color: #fff;
  text-align: center;
  font-size: 5.75rem;
  font-weight: 300;
  line-height: 7rem;
}

.form__input {
  color: #04379e;
  font-size: 5.75rem;
  font-weight: bold;
  line-height: auto;
  resize: none;
  box-shadow: none;
  text-align: center;
  background-color: #94b0f0;
  border: 0;
  border-radius: 1rem;
  outline: none;
  width: auto;
  margin: 4rem 0 0;
  padding: 1rem 15rem;
}

.form__input.valid {
  color: #231f20;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
}

.form__input.invalid {
  color: red;
}

.music {
  background-color: #04379e;
  width: 100%;
  padding: 12em 0 6rem;
}

@media (width <= 800px) {
  .music {
    padding: 6em 0 12rem;
  }
}

.music__title {
  width: 80rem;
  margin: 0 auto;
  padding: 0 0 10rem;
  display: block;
  position: relative;
}

.music__title--text {
  width: 82.5rem;
}

.music__title--arrow {
  width: 10rem;
  display: inline-block;
  position: absolute;
  top: 6rem;
  left: -14rem;
}

@media (width <= 800px) {
  .music__title {
    width: 60rem;
    padding: 0;
  }

  .music__title--text {
    width: 75rem;
  }

  .music__title--arrow {
    width: 7rem;
    top: 3rem;
    left: -10rem;
  }
}

.music__player {
  text-align: center;
  width: 100%;
}

.music__player__controls {
  padding: 0 0 8rem;
}

.music__player__controls--prev {
  background: url("btn-musique-prev.f28e8538.svg") no-repeat;
  width: 6.2rem;
  height: 9rem;
  font-size: 0;
}

.music__player__controls--next {
  background: url("btn-musique-next.eb87f867.svg") no-repeat;
  width: 5.2rem;
  height: 8.6rem;
  font-size: 0;
}

.music__player__controls--playpause {
  background: url("btn-musique-play.48482304.svg") no-repeat;
  width: 11.4rem;
  height: 11.6rem;
  margin: 0 7rem;
  font-size: 0;
}

.music__player__controls--playpause.amplitude-playing {
  background: url("btn-musique-pause.364096b6.svg") no-repeat;
}

.music__player__controls button:hover, .music__player__controls button:active {
  filter: brightness(200%);
}

.music__player__time {
  color: #fff;
  padding: 0 0 4rem;
  font-size: 2.6rem;
}

.music__player__time--progress {
  cursor: pointer;
  background-color: #94b0f0;
  border: 0;
  width: 85.4rem;
  height: 2.8rem;
  margin: 0 1rem;
  -webkit-mask: url("btn-musique-jauge.7efca60c.svg") no-repeat;
  mask: url("btn-musique-jauge.7efca60c.svg") no-repeat;
}

.music__player__time--progress:hover, .music__player__time--progress:active {
  filter: brightness(150%);
}

.music__player__time--progress:first-letter {
  background: #215bdf;
}

.music__player__time--progress::-moz-progress-bar {
  background: #215bdf;
}

@media (width <= 800px) {
  .music__player__time--progress {
    width: 70rem;
  }
}

.music__player__infos {
  text-align: center;
  color: #94b0f0;
  margin: 0 0 12rem;
  padding: 0 4rem;
  font-size: 4.2rem;
}

.music__player__infos--name {
  font-weight: bold;
}

.music__spotify {
  color: #fff;
  text-align: center;
  background: url("lien-spotify.b6380088.svg") 100% 25% / 5.6rem 5.7rem no-repeat;
  padding: 0 7.5rem 0 0;
  font-size: 3.8rem;
  font-weight: 300;
  line-height: 7rem;
  text-decoration: none;
  display: inline-block;
  position: relative;
  left: 25%;
}

.music__spotify:hover {
  filter: brightness(150%);
  text-decoration: underline;
}

.music__spotify:after {
  content: "";
  background: url("fleche-lien.2465c110.svg") 1rem .2rem / contain no-repeat;
  width: 3.5rem;
  height: 2.7rem;
  padding-left: 1rem;
  display: inline-block;
}

.photos {
  background-color: #eff4fd;
  width: 100%;
  padding: 12rem 0 0;
}

@media (width <= 800px) {
  .photos {
    padding: 6rem 0 0;
  }
}

.photos__title {
  width: 100rem;
  margin: 0 auto;
  padding: 0 0 10rem;
  display: block;
  position: relative;
}

.photos__title--text {
  width: 75rem;
}

.photos__title--arrow {
  width: 10rem;
  display: inline-block;
  position: absolute;
  top: 10rem;
  right: 3rem;
}

@media (width <= 800px) {
  .photos__title {
    width: 90rem;
    padding: 0 0 12rem;
  }
}

.photos__month {
  width: 100%;
  max-width: 140rem;
  margin: 0 auto;
  padding: 0 8rem 16rem;
  position: relative;
}

.photos__month--title {
  z-index: 30;
  color: #04379e;
  text-transform: uppercase;
  background-color: #fff;
  padding: 2rem 8rem 3rem;
  font-size: 10rem;
  font-weight: bold;
  line-height: 12rem;
  display: inline-block;
  position: absolute;
}

.photos__month--pic {
  z-index: 20;
  height: auto;
  position: absolute;
}

.photos__month picture {
  z-index: 21;
  margin: 0 auto;
  position: relative;
}

.photos__month picture img {
  image-orientation: none;
  height: auto;
  display: block;
}

@media (width <= 800px) {
  .photos__month picture img {
    max-width: 90vw;
  }

  .photos__month {
    padding: 0 4rem 8rem;
  }
}

.photos__year {
  color: #04379e;
  font-size: 22rem;
  font-weight: 300;
  line-height: 26rem;
  position: relative;
  top: -15rem;
  left: 50%;
}

.photos__year b {
  font-weight: normal !important;
}

@media (width <= 800px) {
  .photos__year {
    top: -10rem;
    left: 25%;
  }
}

.photos .fevrier23 .photos__month--title {
  top: 45rem;
  left: 50%;
}

@media (width <= 800px) {
  .photos .fevrier23 .photos__month--title {
    left: 40%;
  }
}

.photos .fevrier23--1 {
  z-index: 22;
}

.photos .fevrier23--1 img {
  width: 69rem;
}

.photos .fevrier23--2 {
  top: -10rem;
  left: 25%;
}

.photos .fevrier23--2 img {
  width: 90rem;
}

@media (width <= 800px) {
  .photos .fevrier23--2 {
    left: 3%;
  }
}

.photos .mars23 {
  margin-top: 10rem;
}

.photos .mars23 .photos__month--title {
  top: -10rem;
  left: 50%;
}

.photos .mars23--1 {
  left: 5rem;
}

.photos .mars23--1 img {
  width: 66rem;
}

.photos .mars23--2 {
  z-index: 22;
  top: -30rem;
  left: 45%;
}

.photos .mars23--2 img {
  width: 66.4rem;
}

@media (width <= 800px) {
  .photos .mars23--2 {
    left: 25%;
  }
}

.photos .mars23--p1 {
  width: 24.8rem;
  top: 20rem;
  right: 20rem;
}

@media (width <= 800px) {
  .photos .mars23--p1 {
    right: 0;
  }
}

.photos .mars23--p2 {
  width: 37.2rem;
  bottom: 35rem;
  left: 10rem;
}

@media (width <= 800px) {
  .photos .mars23--p2 {
    bottom: 0;
  }

  .photos .avril23 {
    margin-top: 10rem;
  }
}

.photos .avril23 .photos__month--title {
  top: 60rem;
  right: 5%;
}

.photos .avril23--1 {
  z-index: 22;
  left: 22%;
}

.photos .avril23--1 img {
  width: 65.8rem;
}

.photos .avril23--2 {
  top: -2.5rem;
  left: 5rem;
}

.photos .avril23--2 img {
  width: 109rem;
}

@media (width <= 800px) {
  .photos .avril23--2 {
    left: 2%;
  }
}

.photos .mai23 .photos__month--title {
  top: 7rem;
  right: 12%;
}

.photos .mai23--1 {
  z-index: 22;
  left: 25%;
}

.photos .mai23--1 img {
  width: 66.2rem;
}

@media (width <= 800px) {
  .photos .mai23--1 {
    left: 2%;
  }
}

.photos .mai23--2 {
  left: 45%;
}

.photos .mai23--2 img {
  width: 66.2rem;
}

@media (width <= 800px) {
  .photos .mai23--2 {
    left: 25%;
  }
}

.photos .mai23--p1 {
  width: 21.8rem;
  top: 50rem;
  right: 5%;
}

.photos .mai23--p2 {
  width: 37.6rem;
  bottom: 75rem;
  left: 10%;
}

@media (width <= 800px) {
  .photos .mai23--p2 {
    bottom: -25rem;
    left: 0;
  }

  .photos .juin23 {
    margin-top: 30rem;
  }
}

.photos .juin23 .photos__month--title {
  top: 70rem;
  right: 10%;
}

.photos .juin23--1 {
  left: 15%;
}

.photos .juin23--1 img {
  width: 66.2rem;
}

.photos .juin23--2 {
  z-index: 22;
  top: -10rem;
  left: 40%;
}

.photos .juin23--2 img {
  width: 66.2rem;
}

@media (width <= 800px) {
  .photos .juin23--2 {
    left: 25%;
  }
}

.photos .juin23--3 {
  top: -10rem;
  left: -7%;
}

.photos .juin23--3 img {
  width: 141.4rem;
}

@media (width <= 800px) {
  .photos .juin23--3 {
    left: 1%;
  }
}

.photos .juin23--p1 {
  width: 42.8rem;
  top: 100rem;
  left: 0%;
}

@media (width <= 800px) {
  .photos .juin23--p1 {
    top: 215rem;
    left: 30%;
  }

  .photos .juillet23 {
    margin-top: 40rem;
  }
}

.photos .juillet23 .photos__month--title {
  top: 10rem;
  right: 10%;
}

.photos .juillet23--1 {
  left: 15%;
}

.photos .juillet23--1 img {
  width: 66.2rem;
}

@media (width <= 800px) {
  .photos .juillet23--1 {
    left: 0;
  }
}

.photos .juillet23--2 {
  left: 5%;
}

.photos .juillet23--2 img {
  width: 120rem;
}

@media (width <= 800px) {
  .photos .juillet23--2 {
    left: 3%;
  }
}

.photos .juillet23--p1 {
  width: 29.4rem;
  top: 50rem;
  right: 10%;
}

@media (width <= 800px) {
  .photos .juillet23--p1 {
    right: 1%;
  }
}

.photos .aout23 .photos__month--title {
  top: 40rem;
  left: 17%;
}

.photos .aout23--1 {
  z-index: 22;
  left: 50%;
}

.photos .aout23--1 img {
  width: 66.2rem;
}

@media (width <= 800px) {
  .photos .aout23--1 {
    left: 25%;
  }
}

.photos .aout23--2 {
  top: -7rem;
  left: 15%;
}

.photos .aout23--2 img {
  width: 87.2rem;
}

@media (width <= 800px) {
  .photos .aout23--2 {
    left: 5%;
  }

  .photos .septembre23 {
    margin-bottom: 30rem;
  }
}

.photos .septembre23 .photos__month--title {
  top: 45rem;
  left: 48%;
}

@media (width <= 800px) {
  .photos .septembre23 .photos__month--title {
    left: 25%;
  }
}

.photos .septembre23--1 {
  z-index: 22;
  left: 10%;
}

.photos .septembre23--1 img {
  width: 66.6rem;
}

.photos .septembre23--2 {
  top: -7rem;
  left: 42%;
}

.photos .septembre23--2 img {
  width: 66.2rem;
}

@media (width <= 800px) {
  .photos .septembre23--2 {
    left: 25%;
  }
}

.photos .septembre23--p1 {
  width: 35.2rem;
  top: 10rem;
  right: 5%;
}

@media (width <= 800px) {
  .photos .septembre23--p1 {
    top: 178rem;
    right: 30%;
  }

  .photos .octobre23 {
    margin-bottom: 30rem;
  }
}

.photos .octobre23 .photos__month--title {
  bottom: 95rem;
  left: 10%;
}

@media (width <= 800px) {
  .photos .octobre23 .photos__month--title {
    bottom: 85rem;
    left: 5%;
  }
}

.photos .octobre23--1 {
  z-index: 22;
  left: 10%;
}

.photos .octobre23--1 img {
  width: 66rem;
}

.photos .octobre23--2 {
  top: -20rem;
  left: 50%;
}

.photos .octobre23--2 img {
  width: 66rem;
}

@media (width <= 800px) {
  .photos .octobre23--2 {
    top: -10rem;
    left: 23%;
  }
}

.photos .octobre23--3 {
  top: -20rem;
  left: 15%;
}

.photos .octobre23--3 img {
  width: 87.2rem;
}

@media (width <= 800px) {
  .photos .octobre23--3 {
    top: -17rem;
    left: 5%;
  }
}

.photos .octobre23--p1 {
  width: 31.4rem;
  top: 10rem;
  right: 5%;
}

@media (width <= 800px) {
  .photos .octobre23--p1 {
    top: auto;
    bottom: -20rem;
    right: 55%;
  }

  .photos .novembre23 {
    margin: 20rem 0;
  }
}

.photos .novembre23 .photos__month--title {
  left: 10%;
}

.photos .novembre23--1 {
  top: 7rem;
  left: 50%;
}

.photos .novembre23--1 img {
  width: 66rem;
}

@media (width <= 800px) {
  .photos .novembre23--1 {
    left: 28%;
  }
}

.photos .novembre23--2 {
  z-index: 22;
  top: -25rem;
  left: 18%;
}

.photos .novembre23--2 img {
  width: 66rem;
}

@media (width <= 800px) {
  .photos .novembre23--2 {
    left: 5%;
  }
}

.photos .novembre23--p1 {
  width: 34.6rem;
  top: 25rem;
  left: 10%;
}

@media (width <= 800px) {
  .photos .novembre23--p1 {
    top: -35rem;
    left: 60%;
  }
}

.photos .novembre23--p2 {
  width: 31rem;
  top: 100rem;
  right: 5%;
}

@media (width <= 800px) {
  .photos .novembre23--p2 {
    top: auto;
    bottom: 5rem;
  }
}

.photos .decembre23 .photos__month--title {
  top: -10rem;
  left: 50%;
}

@media (width <= 800px) {
  .photos .decembre23 .photos__month--title {
    left: 25%;
  }
}

.photos .decembre23--1 {
  z-index: 22;
  left: 18%;
}

.photos .decembre23--1 img {
  width: 65.4rem;
}

@media (width <= 800px) {
  .photos .decembre23--1 {
    left: 15%;
  }
}

.photos .decembre23--2 {
  top: -12rem;
  left: 40%;
}

.photos .decembre23--2 img {
  width: 66.8rem;
}

@media (width <= 800px) {
  .photos .decembre23--2 {
    left: 27%;
  }
}

.photos .decembre23--p1 {
  width: 26.6rem;
  bottom: 55rem;
  left: 18%;
}

@media (width <= 800px) {
  .photos .decembre23--p1 {
    left: 1%;
  }
}

.photos .janvier24 .photos__month--title {
  top: 20rem;
  left: 15%;
}

@media (width <= 800px) {
  .photos .janvier24 .photos__month--title {
    top: 5rem;
    left: 5%;
  }
}

.photos .janvier24--1 {
  left: 43%;
}

.photos .janvier24--1 img {
  width: 66.8rem;
}

@media (width <= 800px) {
  .photos .janvier24--1 {
    left: 25%;
  }
}

.photos .janvier24--2 {
  z-index: 22;
  top: -25rem;
  left: 13%;
}

.photos .janvier24--2 img {
  width: 66.2rem;
}

@media (width <= 800px) {
  .photos .janvier24--2 {
    left: 3%;
  }
}

.photos .janvier24--p1 {
  width: 26.6rem;
  bottom: 70rem;
  right: 10%;
}

@media (width <= 800px) {
  .photos .janvier24--p1 {
    bottom: 60rem;
    right: 0;
  }

  .photos .fevrier24 {
    padding-bottom: 20rem;
  }
}

.photos .fevrier24 .photos__month--title {
  top: -10rem;
  right: 15%;
}

.photos .fevrier24--1 {
  z-index: 22;
  left: 10%;
}

.photos .fevrier24--1 img {
  width: 66.4rem;
}

@media (width <= 800px) {
  .photos .fevrier24--1 {
    left: 5%;
  }
}

.photos .fevrier24--2 {
  z-index: 23;
  top: -30rem;
  left: 60%;
}

.photos .fevrier24--2 img {
  width: 38.4rem;
}

@media (width <= 800px) {
  .photos .fevrier24--2 {
    left: 55%;
  }
}

.photos .fevrier24--3 {
  top: -35rem;
  left: 15%;
}

.photos .fevrier24--3 img {
  width: 66rem;
}

@media (width <= 800px) {
  .photos .fevrier24--3 {
    left: 10%;
  }
}

.photos .fevrier24--p1 {
  width: 23.4rem;
  bottom: 80rem;
  right: 7%;
}

@media (width <= 800px) {
  .photos .fevrier24--p1 {
    bottom: 10rem;
    right: 40%;
  }
}
/*# sourceMappingURL=index.852625a7.css.map */
