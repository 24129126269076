.music {
  background-color: $blue-darker;
  width: 100%;
  padding: 12em 0 6rem;

  @include mobile {
    padding: 6em 0 12rem;
  }

  &__title {
    display: block;
    margin: 0 auto;
    width: 80rem;
    position: relative;
    padding: 0 0 10rem;

    &--text {
      width: 82.5rem;
    }

    &--arrow {
      width: 10rem;
      display: inline-block;
      position: absolute;
      left: -14rem;
      top: 6rem;
    }

    @include mobile {
      width: 60rem;
      padding: 0 0 0 0;

      &--text {
        width: 75rem;
      }

      &--arrow {
        width: 7rem;
        left: -10rem;
        top: 3rem;
      }

    }
  }

  &__player {
    width: 100%;
    text-align: center;

    &__controls {
      padding: 0 0 8rem 0;

      &--prev {
        width: 6.2rem;
        height: 9rem;
        font-size: 0;
        background: url($imgPath+'btn-musique-prev.svg') no-repeat;
      }

      &--next {
        width: 5.2rem;
        height: 8.6rem;
        font-size: 0;
        background: url($imgPath+'btn-musique-next.svg') no-repeat;
      }

      &--playpause {
        margin: 0 7rem;
        width: 11.4rem;
        height: 11.6rem;
        font-size: 0;
        background: url($imgPath+'btn-musique-play.svg') no-repeat;

        &.amplitude-playing {
          background: url($imgPath+'btn-musique-pause.svg') no-repeat;
        }
      }

      button:hover,
      button:active {
        filter:brightness(200%);
      }

    }

    &__time {
      padding: 0 0 4rem 0;

      font-size: 2.6rem;
      color: $white;


      &--progress {
        border: 0;
        background-color: $blue-light;
        mask: url($imgPath+'btn-musique-jauge.svg') no-repeat;
        width: 85.4rem;
        height: 2.8rem;
        margin: 0 1rem;
        cursor: pointer;

        &:hover,
        &:active {
          filter:brightness(150%);
        }

        &::first-letter,
        &::-moz-progress-bar {
          background: #215BDF;
        }

        @include mobile {
          width: 70rem;
        }
      }
    }

    &__infos {
      text-align: center;
      font-size: 4.2rem;
      color: $blue-light;
      padding: 0 4rem;
      margin: 0 0 12rem;

      &--name {
        font-weight: bold;
      }
    }

  }

  &__spotify {
    position: relative;
    left: 25%;
    display: inline-block;
    font-size: 3.8rem;
    line-height: 7rem;
    font-weight: 300;
    color: $white;
    padding: 0 7.5rem 0 0;
    background: url($imgPath+'lien-spotify.svg') no-repeat 100% 25%;
    background-size: 5.6rem 5.7rem;
    text-decoration: none;
    text-align: center;

    &:hover {
      filter:brightness(150%);
      text-decoration: underline;
    }

    &:after {
      background: url($imgPath+'fleche-lien.svg') no-repeat 1rem 0.2rem;
      background-size: contain;
      display: inline-block;
      padding-left: 1rem;
      width: 3.5rem;
      height: 2.7rem;
      content:"";
    }

  }

}
