:root {
  --scrollbar-width: 0;
}

html {
  font-size: 10px;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

@include mobile {
  html {
    font-size: calc((100vw - var(--scrollbar-width)) / 100);
  }
}

@include desktop {
  html {
    font-size: calc((100vw - var(--scrollbar-width)) / 198);
  }
}

@include large {
  html {
    font-size: 10px;
  }
}

body {
  font-family: $fontRegular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background-color: $blue;
  min-height: 100vh;
}
