.form {
  padding: 20rem 0 20rem;

  @include mobile {
    padding: 10rem 0;
  }

  &__field {
    width: 95rem;
    text-align: center;
    margin: 0 auto;
  }

  &__label {
    color: $white;
    font-weight: 300;
    font-size: 5.75rem;
    line-height: 7rem;
    text-align: center;
  }

  &__input {
    margin: 4rem 0 0 0;
    background-color: $blue-light;
    color: $blue-darker;
    font-weight: bold;
    font-size: 5.75rem;
    line-height: auto;
    resize: none;
    border: 0;
    box-shadow: none;
    outline: none;
    padding: 1rem 15rem;
    border-radius: 1rem;
    width: auto;
    text-align: center;

    &.valid {
      color: $black;
      user-select: none;
      pointer-events:none
    }

    &.invalid {
      color: $red;
    }

  }
}
