.photos {
  background-color: $blue-lighter;
  width: 100%;
  padding: 12rem 0 0;

  @include mobile {
    padding: 6rem 0 0;
  }

  &__title {
    display: block;
    margin: 0 auto;
    width: 100rem;
    position: relative;
    padding: 0 0 10rem;

    &--text {
      width: 75rem;
    }

    &--arrow {
      width: 10rem;
      display: inline-block;
      position: absolute;
      right: 3rem;
      top: 10rem;
    }

    @include mobile {
      width: 90rem;
      padding: 0 0 12rem 0;
    }
  }

  &__month {
    position: relative;
    padding: 0 8rem 16rem;
    width: 100%;
    max-width: 140rem;
    margin: 0 auto;

    &--title {
      position: absolute;
      z-index: $indexPhoto+10;
      display: inline-block;
      font-weight: bold;
      color: $blue-darker;
      font-size: 10rem;
      line-height: 12rem;
      padding: 2rem 8rem 3rem;
      text-transform: uppercase;
      background-color: $white;
    }

    &--pic {
      z-index: $indexPhoto;
      position: absolute;
      height: auto;
    }

    picture {
      z-index: $indexPhoto+1;
      margin: 0 auto;
      position: relative;

      img {
        display: block;
        height: auto;
        image-orientation: none;

        @include mobile {
          max-width: 90vw;
        }
      }
    }

    @include mobile {
      padding: 0 4rem 8rem;
    }
  }

  &__year {
    position: relative;
    font-weight: 300;
    color: $blue-darker;
    font-size: 22rem;
    line-height: 26rem;
    left: 50%;
    top: -15rem;

    b {
      font-weight: normal !important;
    }

    @include mobile {
      left: 25%;
      top: -10rem;
    }
  }

  .fevrier23 {
    .photos__month--title {
      left: 50%;
      top: 45rem;

      @include mobile {
        left: 40%;
      }
    }

    &--1 {
      img {
        width: 69rem;
      }
      z-index: $indexPhoto + 2;
    }

    &--2 {
      img {
        width: 90rem;
      }
      top: -10rem;
      left: 25%;

      @include mobile {
        left: 3%;
      }
    }
  }

  .mars23 {
    margin-top: 10rem;

    .photos__month--title {
      left: 50%;
      top: -10rem;
    }

    &--1 {
      img {
        width: 66rem;
      }
      left: 5rem;
    }

    &--2 {
      img {
        width: 66.4rem;
      }
      top: -30rem;
      left: 45%;
      z-index: $indexPhoto + 2;

      @include mobile {
        left: 25%;
      }
    }

    &--p1 {
      width: 24.8rem;
      top: 20rem;
      right: 20rem;

      @include mobile {
        right: 0;
      }
    }

    &--p2 {
      width: 37.2rem;
      bottom: 35rem;;
      left: 10rem;

      @include mobile {
        bottom: 0;
      }
    }
  }

  .avril23 {

    @include mobile {
      margin-top: 10rem;
    }

    .photos__month--title {
      right: 5%;
      top: 60rem;
    }

    &--1 {
      img {
        width: 65.8rem;
      }
      left: 22%;
      z-index: $indexPhoto + 2;
    }

    &--2 {
      img {
        width: 109rem;
      }
      top: -2.5rem;
      left: 5rem;


      @include mobile {
        left: 2%;
      }
    }
  }

  .mai23 {

    .photos__month--title {
      right: 12%;
      top: 7rem;
    }

    &--1 {
      img {
        width: 66.2rem;
      }
      left: 25%;
      z-index: $indexPhoto + 2;


      @include mobile {
        left: 2%;
      }
    }

    &--2 {
      img {
        width: 66.2rem;
      }
      left: 45%;

      @include mobile {
        left: 25%;
      }
    }

    &--p1 {
      width: 21.8rem;
      top: 50rem;
      right: 5%;
    }

    &--p2 {
      width: 37.6rem;
      bottom: 75rem;
      left: 10%;

      @include mobile {
        left: 0;
        bottom: -25rem;
      }
    }
  }

  .juin23 {

    @include mobile {
      margin-top: 30rem;
    }

    .photos__month--title {
      right: 10%;
      top: 70rem;
    }

    &--1 {
      img {
        width: 66.2rem;
      }
      left: 15%;
    }

    &--2 {
      img {
        width: 66.2rem;
      }
      top: -10rem;
      left: 40%;
      z-index: $indexPhoto + 2;

      @include mobile {
        left: 25%;
      }
    }

    &--3 {
      top: -10rem;
      left: -7%;

      img {
        width: 141.4rem;
      }

      @include mobile {
        left: 1%;
      }
    }

    &--p1 {
      width: 42.8rem;
      top: 100rem;
      left: 0%;

      @include mobile {
        top: 215rem;
        left: 30%;
      }
    }
  }

  .juillet23 {

    @include mobile {
      margin-top: 40rem;
    }

    .photos__month--title {
      right: 10%;
      top: 10rem;
    }

    &--1 {
      img {
        width: 66.2rem;
      }
      left: 15%;

      @include mobile {
        left: 0;
      }
    }

    &--2 {
      img {
        width: 120rem;
      }
      left: 5%;

      @include mobile {
        left: 3%;
      }
    }

    &--p1 {
      width: 29.4rem;
      top: 50rem;
      right: 10%;

      @include mobile {
        right: 1%;
      }
    }
  }

  .aout23 {

    .photos__month--title {
      left: 17%;
      top: 40rem;
    }

    &--1 {
      img {
        width: 66.2rem;
      }
      left: 50%;
      z-index: $indexPhoto + 2;

      @include mobile {
        left: 25%;
      }
    }

    &--2 {
      img {
        width: 87.2rem;
      }
      top: -7rem;
      left: 15%;

      @include mobile {
        left: 5%;
      }
    }
  }

  .septembre23 {
    @include mobile {
      margin-bottom: 30rem;
    }

    .photos__month--title {
      left: 48%;
      top: 45rem;

      @include mobile {
        left: 25%;
      }
    }

    &--1 {
      img {
        width: 66.6rem;
      }
      left: 10%;
      z-index: $indexPhoto + 2;
    }

    &--2 {
      img {
        width: 66.2rem;
      }
      top: -7rem;
      left: 42%;

      @include mobile {
        left: 25%;
      }
    }

    &--p1 {
      width: 35.2rem;
      top: 10rem;
      right: 5%;

      @include mobile {
        top: 178rem;
        right: 30%;
      }
    }
  }

  .octobre23 {

    @include mobile {
      margin-bottom: 30rem;
    }

    .photos__month--title {
      bottom: 95rem;
      left: 10%;

      @include mobile {
        bottom: 85rem;
        left: 5%;
      }
    }

    &--1 {
      img {
        width: 66rem;
      }
      left: 10%;
      z-index: $indexPhoto + 2;
    }

    &--2 {
      img {
        width: 66rem;
      }
      top: -20rem;
      left: 50%;

      @include mobile {
        left: 23%;
        top: -10rem;
      }
    }

    &--3 {
      img {
        width: 87.2rem;
      }
      top: -20rem;
      left: 15%;

      @include mobile {
        left: 5%;
        top: -17rem;
      }
    }

    &--p1 {
      width: 31.4rem;
      top: 10rem;
      right: 5%;

      @include mobile {
        right: 55%;
        top: auto;
        bottom: -20rem;
      }
    }
  }

  .novembre23 {

    @include mobile {
      margin: 20rem 0;
    }

    .photos__month--title {
      left: 10%;
    }

    &--1 {
      img {
        width: 66rem;
      }
      top: 7rem;
      left: 50%;

      @include mobile {
        left: 28%;
      }
    }

    &--2 {
      img {
        width: 66rem;
      }
      top: -25rem;
      left: 18%;
      z-index: $indexPhoto + 2;

      @include mobile {
        left: 5%;
      }
    }

    &--p1 {
      width: 34.6rem;
      top: 25rem;
      left: 10%;

      @include mobile {
        top: -35rem;
        left: 60%
      }
    }

    &--p2 {
      width: 31rem;
      top: 100rem;
      right: 5%;

      @include mobile {
        top: auto;
        bottom: 5rem;
      }
    }
  }

  .decembre23 {

    .photos__month--title {
      left: 50%;
      top: -10rem;

      @include mobile {
        left: 25%;
      }
    }

    &--1 {
      img {
        width: 65.4rem;
      }
      left: 18%;
      z-index: $indexPhoto + 2;

      @include mobile {
        left: 15%;
      }
    }

    &--2 {
      img {
        width: 66.8rem;
      }
      top: -12rem;
      left: 40%;

      @include mobile {
        left: 27%;
      }
    }

    &--p1 {
      width: 26.6rem;
      bottom: 55rem;
      left: 18%;

      @include mobile {
        left: 1%;
      }
    }

  }

  .janvier24 {

    .photos__month--title {
      left: 15%;
      top: 20rem;

      @include mobile {
        top: 5rem;
        left: 5%;
      }
    }

    &--1 {
      img {
        width: 66.8rem;
      }
      left: 43%;

      @include mobile {
        left: 25%;
      }
    }

    &--2 {
      img {
        width: 66.2rem;
      }
      top: -25rem;
      left: 13%;
      z-index: $indexPhoto + 2;

      @include mobile {
        left: 3%;
      }
    }

    &--p1 {
      width: 26.6rem;
      bottom: 70rem;
      right: 10%;

      @include mobile {
        right: 0;
        bottom: 60rem;
      }
    }

  }

  .fevrier24 {

    @include mobile {
      padding-bottom: 20rem;
    }

    .photos__month--title {
      right: 15%;
      top: -10rem;
    }

    &--1 {
      img {
        width: 66.4rem;
      }
      left: 10%;
      z-index: $indexPhoto + 2;

      @include mobile {
        left: 5%;
      }
    }

    &--2 {
      img {
        width: 38.4rem;
      }
      top: -30rem;
      left: 60%;
      z-index: $indexPhoto + 3;

      @include mobile {
        left: 55%;
      }
    }

    &--3 {
      img {
        width: 66rem;
      }
      top: -35rem;
      left: 15%;

      @include mobile {
        left: 10%;
      }
    }

    &--p1 {
      width: 23.4rem;
      bottom: 80rem;
      right: 7%;

      @include mobile {
        bottom: 10rem;
        right: 40%;
      }
    }

  }
}
