@mixin desktop {
  @media all and (min-width: $screen-m ) {
    @content;
  }
}

@mixin mobile {
  @media all and (max-width: $screen-m) {
    @content;
  }
}

@mixin large {
  @media all and (min-width: $screen-l ) {
    @content;
  }
}
