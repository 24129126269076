.header {
  background-color: $blue;
  position: relative;

  &__title {
    position: relative;
    z-index: $indexText;
    padding: 15rem 0 0;
    margin: 0 auto;
    width: 66.3rem;

    img {
      width: 66.3rem;
      height: auto;
    }

    @include mobile {
      padding: 20rem 0 0;
      z-index: $indexText+2;
    }
  }

  &__baseline {
    position: relative;
    z-index: $indexText+2;
    text-align: right;
    padding: 5rem 20rem 10rem 0;

    img {
      width: 83.1rem;
      height: auto;
    }

    @include mobile {
      padding: 10rem 2.5rem 10rem 0;
    }
  }

  &__cat {
    position: absolute;
    width: 100%;
    z-index: $indexText+5;

    &--left {
      position: absolute;
      top: -20rem;
      left: 25%;

      img {
        width: 20.8rem;
        height: auto;
      }

      @include mobile {
        left: 15%
      }
    }

    &--right {
      position: absolute;
      top: -12rem;
      right: 25%;

      img {
        width: 25.5rem;
        height: auto;
      }

      @include mobile {
        right: 15%
      }
    }
  }

  &__wool {
    position: absolute;
    top: 37rem;
    left: 3.5%;
    z-index: $indexText+1;

    img {
      width: 130rem;
      height: auto;
    }

    @include mobile {
      left: -30rem;
    }
  }
}
