@font-face {
  font-family: 'Brown';
  font-display: swap;
  src: url('../assets/fonts/brown-regular-webfont.woff2') format('woff2'),
       url('../assets/fonts/brown-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Brown';
  font-display: swap;
  src: url('../assets/fonts/brown-light-webfont.woff2') format('woff2'),
       url('../assets/fonts/brown-light-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Brown';
  font-display: swap;
  src: url('../assets/fonts/brown-bold-webfont.woff2') format('woff2'),
       url('../assets/fonts/brown-bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

$fontRegular: "Brown", sans-serif;
