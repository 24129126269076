.footer {
  background-color: $blue;
  padding: 10rem 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  @include mobile {
    display: block;
    padding: 15rem 5rem 10rem;
  }

  a {
    font-size: 4.1rem;
    color: $white;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &__top {
    width: 50%;
    text-align: left;
    text-indent: 10rem;

    &:after {
      background: url($imgPath+'lien-top.svg') no-repeat;
      background-size: cover;
      display: inline-block;
      position: absolute;
      top: 3rem;
      left: 3rem;
      width: 9.8rem;
      height: 12.2rem;
      content:"";

      @include mobile {
        top: 6rem;
        left: 20%;
      }
    }

    @include mobile {
      display: block;
      width: 100%;
      text-indent: 0;
      margin: 0 0 5rem 0;
      text-align: center;
    }
  }

  &__mailto {
    width: 50%;
    text-align: right;
    line-height: 8rem;
    padding-right: 12rem;

    &:after {
      background: url($imgPath+'lien-mailto.svg') no-repeat;
      background-size: cover;
      display: inline-block;
      position: absolute;
      right: 3rem;
      width: 12rem;
      height: 8.8rem;
      content:"";

      @include mobile {
        right: 15%;
      }
    }

    @include mobile {
      display: block;
      width: 100%;
      padding: 0;
      text-align: center;
    }
  }
}
